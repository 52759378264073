







































































































































































import { Component, Vue } from 'vue-property-decorator'
import API from '@/api/products'
import { ProductListItem, ProductTerm } from '@/shared/models/cakes'
import BezeButton from '@/components/common/BezeButton.vue'
import BezeChip from '@/components/common/BezeChip.vue'
import SingleCakeSlider from '@/components/cakes/single/Slider.vue'
import SingleCakeFillings from '@/components/cakes/single/Fillings.vue'
import Logo from '@/components/common/Logo.vue'
import ArrowIcon from '@/components/icons/ArrowIcon'
import FillingIcon from '@/components/icons/FillingIcon'
import WeightIcon from '@/components/icons/WeightIcon'
import LayersIcon from '@/components/icons/LayersIcon'
import ExclamationIcon from '@/components/icons/ExclamationIcon'
import { CONTACTS } from '@/shared/const'
import Phone from '@/components/common/Phone.vue'
import Loader from '@/components/common/Loader.vue'
import { CakeFilling } from '@/shared/models/fillings'
import RequestOrderModalButton from '@/components/common/RequestOrderModalButton'

type Control = 'weight' | 'layers'
const layersMap: Record<number, number> = {}
const weightMap: Record<number, number> = {}
const AFCFieldName: {[key in Control]: Record<string, string>} = {
  weight: {
    min: 'cake_min_weight',
    max: 'cake_max_weight'
  },
  layers: {
    min: 'cake_min_layers',
    max: 'cake_max_layers'
  }
}

@Component({
  components: {
    SingleCakeSlider,
    SingleCakeFillings,
    BezeButton,
    BezeChip,
    Logo,
    Phone,
    ArrowIcon,
    WeightIcon,
    LayersIcon,
    FillingIcon,
    ExclamationIcon,
    Loader,
    RequestOrderModalButton
  }
})
export default class ProductSingle extends Vue {
  isLoading = true
  isVariantLoading = false
  currentVariant = ''
  currentVariantDescription = ''
  isFillingModalVisible = false
  product: ProductListItem | null = null
  pickedFilling: CakeFilling | null = null

  controlState: { [key in Control]: { value: number } } = {
    weight: {
      value: 0
    },
    layers: {
      value: 0
    }
  }

  // eslint-disable-next-line
  data() {
    return {
      CONTACTS
    }
  }

  get fillings(): Array<CakeFilling> {
    return this.$store.getters.fillings
  }

  get cakeLayersLabel(): string {
    let label = ''
    switch (Number(this.product?.acf.cake_min_layers)) {
      case 1:
        label = 'ярус'
        break
      case 2:
      case 3:
      case 4:
        label = 'яруса'
        break
      default:
        label = 'ярусов'
    }

    return label
  }

  // can we order this product or make a request only
  get isOnRequestOnly(): boolean {
    return !!this.product?.virtual
  }

  get isCakeInRegularCategory(): boolean {
    return this.product?.categories?.findIndex((cat: ProductTerm) => ['regular', 'desserts', 'korporativnye-podarki', 'gastroboksy', 'pirogi'].includes(cat.slug)) !== -1
  }

  get isVariativeProduct(): boolean {
    return ['variable', 'variation'].includes(this.product?.type || '')
  }

  get isProductHasLongDelivery(): boolean {
    return this.product?.categories?.findIndex((cat: ProductTerm) => ['gastroboksy'].includes(cat.slug)) !== -1
  }

  get isProductHas3daysDelivery(): boolean {
    return this.product?.categories?.findIndex((cat: ProductTerm) => ['pirogi'].includes(cat.slug)) !== -1
  }

  get hasCakeFillingPicker(): boolean {
    return this.product?.acf.hasFillingPicker === 'yes'
  }

  get isCakeHasNoWeight(): boolean {
    if (!this.product) return false
    return +this.product?.acf.cake_min_weight === +this.product?.acf.cake_max_weight || !this.product?.acf.cake_max_layers
  }

  get hasCrossSells(): boolean {
    return Boolean(this.product?.cross_sell_ids?.length)
  }

  get isNumberCake(): boolean {
    return this.product?.acf?.isNumberCake === 'yes'
  }

  get isCakeHasNoLayersToPick(): boolean {
    if (!this.product) return false
    return Number(this.product?.acf.cake_min_layers) === Number(this.product?.acf.cake_max_layers)
  }

  get totalPrice(): number {
    if (!this.product) return 0
    return this.controlState.weight.value || this.isCakeHasNoWeight
      ? Number(this.product?.price) * Number(this.isCakeHasNoWeight ? (!this.product?.acf.cake_max_layers ? 1 : this.product?.acf.cake_min_weight) : this.controlState.weight.value)
      : Number(this.product?.price)
  }

  get isCakeInCart(): ProductListItem {
    return this.$store.getters.cart.find((cake: ProductListItem) => cake.id === this.product?.id)
  }

  created(): void {
    API.getProductById(+this.$route.params.id).then(({ data }) => {
      this.product = data
      this.currentVariant = this.product.attributes?.[0]?.options[0] || ''

      if (this.isCakeInCart) {
        this.controlState.weight.value = this.isCakeInCart.weight || 0
        this.controlState.layers.value = this.isCakeInCart.layers || Number(this.product?.acf?.cake_min_layers) || 0
        this.pickedFilling = this.fillings.find(filling => filling.id === this.isCakeInCart.filling?.id) || null
      }

      if (!this.isCakeInRegularCategory && !this.isCakeHasNoWeight && !this.isCakeHasNoLayersToPick) {
        // составить мапу соответсвия каждого кг.веса для каждого яруса и наоборот (короче enum нужен на килограмы и ярусы, но эти значения у каждого торта свои)
        // чтобы если выбрали, например 10кг, то необходимо подставить в поле "ярус" соответствующее значение яруса для такого веса
        // и наоборот если выбрали 3 яруса то в weightMap по выбраному ярусу находим минимальное значение кг, которое доступно для этого яруса и ставим его
        const layers = (this.product.acf.layers_limits as string).split(',')
        for (let i = +this.product.acf.cake_min_weight; i < (+this.product.acf.cake_min_weight + layers.length); i++) {
          layersMap[i] = +layers[i - (+this.product.acf.cake_min_weight)]
          if (!weightMap[+layers[i - (+this.product.acf.cake_min_weight)]]) {
            weightMap[+layers[i - (+this.product.acf.cake_min_weight)]] = i
          }
        }
      }

      this.currentVariant && this.getVariantProduct(0)
    }).finally(() => {
      this.isLoading = false
    })

    this.fillings.length || this.$store.dispatch('getFillings')
  }

  getVariantProduct(index: number): void {
    if (!this.product?.variations?.length) return
    this.isVariantLoading = true
    API.getProductById(this.product.variations[index]).then(({ data }) => {
      // eslint-disable-next-line camelcase
      const { id, price, description, parent_id, attributes, images } = data
      if (this.product) {
        this.product.id = id
        this.product.images = images
        this.product.price = price
        this.currentVariantDescription = description
        // eslint-disable-next-line camelcase
        this.product.parent_id = parent_id
        this.currentVariant = attributes?.[0].option || ''
      }
    }).finally(() => {
      this.isVariantLoading = false
    })
  }

  saveFilling(filling: CakeFilling): void {
    if (filling?.id) {
      this.pickedFilling = { ...filling }
      if (this.isCakeInCart) {
        this.addCakeToCart()
        this.$toasted.success('Корзина обновлена')
      }
    }
    this.isFillingModalVisible = false
  }

  addCakeToCart(): void {
    if (!this.product) return

    this.product.weight = +this.controlState.weight.value || +this.product?.acf?.cake_min_weight
    this.product.layers = +this.controlState.layers.value || +this.product?.acf?.cake_min_layers || 0

    if (this.pickedFilling?.id) {
      this.product.filling = {
        id: this.pickedFilling?.id,
        name: this.pickedFilling?.title.rendered
      }
    }
    this.$store.dispatch('addToCart', { ...this.product })
  }

  checkForCorrectNumberValue(value: number, controlType: Control): void {
    if (!this.product) return

    if (+this.product.acf[AFCFieldName[controlType].min] > value) {
      this.controlState[controlType].value = +this.product.acf[AFCFieldName[controlType].min]
    } else if (+this.product.acf[AFCFieldName[controlType].max] < +value) {
      this.controlState[controlType].value = +this.product.acf[AFCFieldName[controlType].max]
    }

    if (controlType === 'weight') {
      this.controlState.layers.value = layersMap[+this.controlState.weight.value]
    } else if (controlType === 'layers') {
      this.controlState.weight.value = weightMap[+this.controlState.layers.value]
    }
  }

  calcWeight(type: 'inc' | 'decr'): void {
    type === 'inc' ? this.controlState.weight.value++ : this.controlState.weight.value--
    this.checkForCorrectNumberValue(this.controlState.weight.value, 'weight')

    this.isCakeInCart && this.addCakeToCart()
  }
}
