



























































import Vue from 'vue'
import Component from 'vue-class-component'
import { CakeFilling } from '@/shared/models/fillings'
import BezeButton from '@/components/common/BezeButton.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import Loader from '@/components/common/Loader.vue'
import ArrowIcon from '@/components/icons/ArrowIcon'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    VueSlickCarousel,
    BezeButton,
    Loader,
    ArrowIcon
  }
})
export default class SingleCakeFillings extends Vue {
  isSlideTransitioning = false
  curSlideIndex = this.initSlideIdxToShow
  sliderConfig = {
    infinite: false,
    speed: 1000,
    arrows: false,
    dots: false,
    initialSlide: this.initSlideIdxToShow
  }

  @Prop({ default: 0 }) fillingToShow!: number
  @Prop({ default: false }) fixBody!: boolean
  @Prop({ default: () => [] }) cakeCategories!: number[]

  get fillings(): Array<CakeFilling> {
    return this.$store.getters.fillings.filter((filling: CakeFilling) => {
      return Array.isArray(filling.acf?.disabled_categories)
        ? !filling.acf.disabled_categories.some((catId) => this.cakeCategories.includes(catId))
        : true
    })
  }

  get initSlideIdxToShow(): number {
    const idx = this.fillings.findIndex(filling => filling.id === this.fillingToShow)
    return idx !== -1 ? idx : 0
  }

  mounted(): void {
    this.fillings.length || this.$store.dispatch('getFillings')
    if (this.fixBody) {
      document.body.style.overflow = 'hidden'
    }
  }

  beforeDestroy(): void {
    if (this.fixBody) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.body.style.overflow = null
    }
  }

  changeSlide(slideIdx: number, isTransitioning: boolean): void {
    this.curSlideIndex = slideIdx
    this.isSlideTransitioning = isTransitioning
  }
}
