




import Vue from 'vue'
import Component from 'vue-class-component'
import BezeButton from '@/components/common/BezeButton.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    BezeButton
  }
})
export default class RequestOrderModalButton extends Vue {
  @Prop({ default: '' }) productName!: string

  openModal() {
    this.$store.dispatch('toggleModal',
      {
        open: true,
        modalName: 'requestModal',
        data: {
          productName: this.productName
        }
      }
    )
  }
}
