



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ProductImage } from '@/shared/models/cakes'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

@Component({
  components: {
    VueSlickCarousel
  }
})
export default class SingleCakeSlider extends Vue {
  sliderConfig = {
    lazyLoad: 'progressive',
    infinite: false,
    speed: 800,
    arrows: false,
    dots: true,
    dotsClass: 's-product-slide-thumbs'
  }

  @Prop({
    required: true,
    default: () => []
  }) slides!: Array<ProductImage>
}
