<template>
  <svg width="45" height="19" viewBox="0 0 45 19" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.54775 10.0014L42.9915 10.0014L35.1709 17.822C34.9411 18.0518 34.9411 18.4241 35.1709 18.6539C35.4007 18.8837 35.773 18.8837 36.0028 18.6539L44.8276 9.82907C45.0575 9.59924 45.0575 9.22694 44.8276 8.99716L36.0028 0.172356C35.8879 0.0574673 35.7374 -3.00493e-06 35.5868 -2.99177e-06C35.4363 -2.97861e-06 35.2858 0.0574674 35.1709 0.172357C34.941 0.402186 34.941 0.774482 35.1709 1.00426L42.9915 8.82475L1.54769 8.82475C1.2225 8.82475 0.959354 9.0879 0.959354 9.41309C0.959354 9.73828 1.22256 10.0014 1.54775 10.0014Z"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
