<template>
  <svg height="512" viewBox="0 0 24 24" width="512" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m9.5 9c-.276 0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5s.5.224.5.5v3c0 .276-.224.5-.5.5z"/>
    </g>
    <g>
      <path d="m14.5 9c-.276 0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5s.5.224.5.5v3c0 .276-.224.5-.5.5z"/>
    </g>
    <g>
      <path d="m20.917 6h-17.976c-1.077 0-2.03-.687-2.371-1.709l-.545-1.633c-.05-.152-.025-.32.069-.45.094-.131.245-.208.406-.208h23c.167 0 .323.084.416.223s.11.315.045.47l-.737 1.77c-.389.933-1.295 1.537-2.307 1.537zm-19.724-3 .325.975c.205.613.777 1.025 1.423 1.025h17.975c.607 0 1.151-.362 1.385-.923l.449-1.077z"/>
    </g>
    <g>
      <path d="m19.5 22h-15c-.827 0-1.5-.673-1.5-1.5 0-.065.004-.129.012-.19l1.271-10.121c.158-1.249 1.224-2.189 2.481-2.189h10.472c1.257 0 2.323.94 2.48 2.188l1.272 10.123c.008.06.012.124.012.189 0 .827-.673 1.5-1.5 1.5zm-12.736-13c-.754 0-1.394.564-1.488 1.313l-1.272 10.124c-.004.338.22.563.496.563h15c.276 0 .5-.225.5-.5l-1.276-10.187c-.094-.749-.734-1.313-1.488-1.313z"/>
    </g>
    <g>
      <path d="m16 17c-.276 0-.5-.224-.5-.5 0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5c0-2.481 2.019-4.5 4.5-4.5s4.5 2.019 4.5 4.5c0 .276-.224.5-.5.5z"/>
    </g>
    <g>
      <path d="m12 18c-.075 0-.151-.017-.223-.053-.247-.124-.347-.424-.224-.671l1-2c.124-.246.423-.348.671-.224.247.124.347.424.224.671l-1 2c-.089.175-.265.277-.448.277z"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: 'WeightIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
