<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 24 24" :fill="fill">
    <path d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5zM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12z"></path>
    <path d="M10.875 16c0-.621.504-1.125 1.125-1.125h.01c.621 0 1.125.504 1.125 1.125v.01c0 .621-.504 1.125-1.125 1.125H12a1.125 1.125 0 0 1-1.125-1.125zM12 12.75a.75.75 0 0 1-.75-.75V8a.75.75 0 0 1 1.5 0v4a.75.75 0 0 1-.75.75z"></path>
  </svg>
</template>

<script>
export default {
  name: 'ExclamationIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
